import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { depositStakeAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function DepositAndStakeFor({ networkID, provider }) {
    const [uris, setURIs] = useState(['']);
    const [amount, setAmount] = useState(['']);


    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            console.log({
                account:form["0"]?.value,
                amount,
                uris,
            })
            dispatch(depositStakeAsyncThunk({
                account:form["0"]?.value,
                amount,
                uris,
                // uris:[],
                networkID, provider
            }));
        }
    };

    return (
        <div className='method-component'>
            <h2>Deposit and Stake Your USDT</h2>
            <p>You can deposit and Stake your USDT and get rewards according to Dashbone policy</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>
                        {/*  */}
                        <div className='select-field'>

                            <Form.Group >
                                <div>
                                    <Form.Control
                                        type="text"
                                        required
                                        pattern="^0x[a-fA-F0-9]{40}$"
                                        placeholder="Enter Wallet Address"
                                        aria-label="Enter Wallet Address"
                                        aria-describedby="basic-addon2"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid wallet.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="valid">
                                        Looks good!
                                    </Form.Control.Feedback>
                                </div>

                            </Form.Group>

                        </div>
                        {/*  */}
                        <Form.Group >
                            <div>
                                <Form.Control
                                    type="number"
                                    name="Amount"
                                    value={amount}
                                    onChange={(e) => {
                                        if (+e.target.value > 50000) {
                                            return
                                        }
                                        setAmount(e.target.value);
                                        if (+e.target.value < 500) {
                                            setURIs([])
                                        } else {
                                            console.log("e", e.target.value)
                                            console.log("e", Math.floor(+e.target.value / 500))
                                            setURIs(Array.from({ length: Math.floor(+e.target.value / 500) }, v => ''))
                                        }
                                    }}
                                    required
                                    // pattern="^(?=.*\d).{8,}$"
                                    placeholder="Amount..."
                                    aria-label="Amount..."
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Amount.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>

                        </Form.Group>

                        {/*  */}
                        {uris.map((url, index) => (<Form.Group >
                            <div>
                                <Form.Control
                                    type="text"
                                    name="Link"
                                    required
                                    value={url}
                                    onChange={(e) => setURIs(uris.map((el, i) => i == index ? e.target.value : el))}
                                    // pattern="^(?=.*\d).{8,}$"
                                    placeholder="URI..."
                                    aria-label="URI..."
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Link.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>

                        </Form.Group>))}
                        {/*  */}
                    </div>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"depositStakeAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
