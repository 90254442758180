import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, ClaimNFTAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function ClaimLeml({ networkID, provider, address }) {

    const { analytics } = useSelector(state => state.bank);
    const [uris, setURIs] = useState([]);
    const { claimableNFT } = analytics;
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(ClaimNFTAsyncThunk({
                uris,
                networkID, provider
            }));
        }
    };
    useEffect(() => {
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        return () => {
        }
    }, [])
    useEffect(() => {
        setURIs(Array.from({ length: claimableNFT }, v => ''))
    }, [analytics])


    return (
        <div className='method-component'>
            <h2>Claim your NFT</h2>
            <p>You have {claimableNFT} LDBN for claim, click to claim.</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}> 
                <div className='contact-form'>
                    <div className='select-field'>

                        {uris.map((url, index) => (<Form.Group >
                            <div>
                                <Form.Control
                                    type="text"
                                    name="Link"
                                    required
                                    value={url}
                                    onChange={(e) => setURIs(uris.map((el, i) => i == index ? e.target.value : el))}
                                    // pattern="^(?=.*\d).{8,}$"
                                    placeholder="URI..."
                                    aria-label="URI..."
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Link.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>

                        </Form.Group>))}
                        <div className='inner-row'>
                            <CustomButton
                                title={'Mint NFTs'}
                                type="submit"
                                slice={'bank'}
                                action={"ClaimNFTAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
