import React from 'react'

export default function RemoveTransferAdmin() {
  return (
    <div className='method-component'>
      <h1>Unpuse Transfers{ }</h1>
      <p>In case of irrelevant things related to regulations if you want to puse all the transactions click this</p>
      {/* <CustomButton
      title="Submit"
      type="submit"
      onClick={() => dispatch(unpauseAsyncThunk({ networkID, provider }))}
      variant="warning"
      btnClass="buy-btn w-100"
      action={"getSubscribedAsyncThunk"}
    /> */}
    </div>
  )
}
