import React from 'react'
import NetworkError from './NetworkError'

export default function Index() {
  return (
    <div>
      <NetworkError />
    </div>
  )
}
