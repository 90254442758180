// ** Reducers Imports
import cms from "./pagesSlices/cmsSlice";
import bank from './pagesSlices/emolume_bank'

import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  cms,
  error,
  bank
});
// const rootReducer = {
//   cms,
//   error,
// };

export default rootReducer;
