import { i18n } from "@lingui/core";
import { en, fr, ko, tr } from "make-plural/plurals";


export const locales = {
  en: { flag: "us", plurals: en },
  fr: { flag: "fr", plurals: fr },
  ko: { flag: "kr", plurals: ko },
  tr: { flag: "tr", plurals: tr },
};

// Load locale data
for (var [key, locale] of Object.entries(locales)) {
  i18n.loadLocaleData(key, { plurals: locale.plurals });
}

async function fetchLocale(locale = "en") {
  const { messages } = await import(
    /* webpackChunkName: "[request]" */ `../locales/translations/olympus-frontend/${locale}/messages`
  );
  i18n.load(locale, messages);
  i18n.activate(locale);
}
export function selectLocale(locale) {
  window.localStorage.setItem("locale", locale);
  return fetchLocale(locale);
}
export function initLocale() {
  var locale = window.localStorage.getItem("locale");
  if (!Object.keys(locales).includes(locale)) locale = "en";
  fetchLocale(locale);
}
