import React, { useRef, useState } from 'react'
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { useWeb3Context } from '../../context'

export function Web3Address() {
  const { address } = useWeb3Context()
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (

    <>
      <div className="nav nav-link"
        ref={target} onClick={() => {
          setShow(!show)
          navigator.clipboard.writeText(address)
          setTimeout(() => {
            setShow(!show)
          }, 1000);
        }}
        onMouseOut={()=>setShow(false)}
      // onClick={()=>navigator.clipboard.writeText(address)}
      >
        {address && address.substring(0, 6)}
      </div>
      {/* <Button ref={target} onClick={() => setShow(!show)}>
        Click me!
      </Button> */}
      <Overlay target={target.current} show={show} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Copied to clipboard
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
