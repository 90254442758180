import React from 'react'
import { useDispatch } from 'react-redux'
import { pauseAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton'

export default function Pause({networkID, provider}) {
    console.log("provider",provider)
    const dispatch =useDispatch();

    return (
        <div className='method-component'>
            <h1>Puse Transfers{}</h1>
            <p>In case of irrelevant things related to regulations if you want to puse all the transactions click this</p>
            <CustomButton
                  title="Submit"
                  type="submit"
                  onClick={()=>dispatch(pauseAsyncThunk({networkID,provider}))}
                  variant="warning"
                  btnClass="buy-btn w-100"
                  action={"getSubscribedAsyncThunk"}
                />
        </div>
    )
}