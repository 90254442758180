import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3Context } from '../../context'
import { appLoadDataAsyncThunk } from '../../redux/pagesSlices/emolume_bank'
import CustomButton from '../customs/CustomButton'
export default function Default() {
  const { analytics } = useSelector(state => state.bank)
  const dispatch = useDispatch()
  const { web3Provider, address, network, } = useWeb3Context()
  const { isOwner, myBalance, myNFTs, claimableNFT, claimableLDBN, depositFee, withdrawFee, totalStaked, checkStaked, minWithdraw, minDeposit, totalDeposit, totalrewardGoes, lemlBankAddress, ldbnPerUsdt, nftBankAddress, OneNFTforUSDT, lemlBalance, myReward } = analytics;
  return (
    <div className='method-component'>
      <h2>Hi {isOwner ? 'Admin' : 'User'}</h2>
      <p>{isOwner ? 'You can manage your bank from here all available methods are listed on left sidebar' : "Hi user you can view your account detail like current balance, deposit balance, rewards and more."}</p>

      <div className='row'>
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className="fa-duotone fa-circle-dollar"></i>
            </div>
            <div className='balance'>
              <h5>
                {myBalance.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                My USDT Balance
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className="fa-duotone fa-hexagon-vertical-nft"></i>
            </div>
            <div className='balance'>
              <h5>
                {myNFTs}
              </h5>
              <small> USDT</small>
              <p>
                My NFT
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className="fa-duotone fa-coins"></i>
            </div>
            <div className='balance'>
              <h5>
                {lemlBalance.toFixed(2)}
              </h5>
              <small> LDBN</small>
              <p>
                My LDBN
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className="fa-duotone fa-square-dollar"></i>
            </div>
            <div className='balance'>
              <h5>
                {claimableNFT}
              </h5>
              <small> NFT</small>
              <p>
                Claimable NFT
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-sack-dollar'></i>
            </div>
            <div className='balance'>
              <h5>
                {claimableLDBN}
              </h5>
              <small> LDBN</small>
              <p>
                Claimable LDBN
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className="fa-duotone fa-coins"></i>
            </div>
            <div className='balance'>
              <h5>
                {myReward.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                My USDT Reward
              </p>
            </div>
          </div>
        </div>


        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-money-bill-transfer'></i>
            </div>
            <div className='balance'>
              <h5>
                {analytics?.deposited.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                My Deposited
              </p>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-money-from-bracket'></i>
            </div>
            <div className='balance'>
              <h5>
                {analytics?.wdrown.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                My Withdrown
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-network-wired'></i>
            </div>
            <div className='balance'>
              <h5>
                {checkStaked?.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                My Staked
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-network-wired'></i>
            </div>
            <div className='balance'>
              <h5>
                {minDeposit?.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                Min Deposit
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-network-wired'></i>
            </div>
            <div className='balance'>
              <h5>
                {minWithdraw?.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                Min Withdraw
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-circle-dollar-to-slot'></i>
            </div>
            <div className='balance'>
              <h5>
                {totalrewardGoes.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                Total Reward in public
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-receipt'></i>
            </div>
            <div className='balance'>
              <h5>
                {withdrawFee.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                Withdraw Fee
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className='col-lg-4'>
          <div className='widget-card'>
            <div className='icon'>
              <i className='fa-duotone fa-receipt'></i>
            </div>
            <div className='balance'>
              <h5>
                {depositFee.toFixed(2)}
              </h5>
              <small> USDT</small>
              <p>
                Deposit Fee
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        {/* admin information */}
        {isOwner && (<>
          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-file-contract'></i>
              </div>
              <div className='balance'>
                <h5>
                  {analytics?.tetherInContract.toFixed(2)}
                </h5>
                <small> USDT</small>
                <p>
                  Tether In Contract
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-file-contract'></i>
              </div>
              <div className='balance'>
                <h5>
                  {analytics?.dbnInContract.toFixed(2)}
                </h5>
                <small> DBN</small>
                <p>
                  DBN In Contract
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-money-from-bracket'></i>
              </div>
              <div className='balance'>
                <h5>
                  {totalDeposit.toFixed(2)}
                </h5>
                <small> USDT</small>
                <p>
                  Total Deposit USDT
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-equals'></i>
              </div>
              <div className='balance'>
                <h5>
                  {ldbnPerUsdt}
                </h5>
                <small> LDBN</small>
                <p>
                LDBN per USDT
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-piggy-bank'></i>
              </div>
              <div className='balance'>
                <h5>
                  {analytics?.collectedFee.toFixed(2)}
                </h5>
                <small> USDT</small>
                <p>
                  Fee Collected
                </p>
              </div>
            </div>
          </div>
          {/*  */}

          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-circle-dollar-to-slot'></i>
              </div>
              <div className='balance'>
                <h5>
                  {totalStaked.toFixed(2)}
                </h5>
                <small> USDT</small>
                <p>
                  Total Staked
                </p>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='col-lg-4'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-hexagon-vertical-nft'></i>
              </div>
              <div className='balance'>
                <h5>
                  {OneNFTforUSDT}
                </h5>
                <small> NFT</small>
                <p>
                  1 NFT for USDT
                </p>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='col-lg-6'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-building-columns'></i>
              </div>
              <div className='balance'>
                <h5>
                  {lemlBankAddress && lemlBankAddress.substring(0, 5) + '...' + lemlBankAddress.substring(lemlBankAddress.length - 5, lemlBankAddress.length)}
                </h5>
                <small> USDT</small>
                <p>
                  Bank Address in LDBN
                </p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className='col-lg-6'>
            <div className='widget-card'>
              <div className='icon'>
                <i className='fa-duotone fa-building-columns'></i>
              </div>
              <div className='balance'>
                <h5>
                  {nftBankAddress && nftBankAddress.substring(0, 5) + '...' + nftBankAddress.substring(nftBankAddress.length - 5, nftBankAddress.length)}
                </h5>
                <small> USDT</small>
                <p>
                  Bank Address in NFT
                </p>
              </div>
            </div>
          </div>
        </>)}
      </div>
      <CustomButton
        title="Reload Data"
        type="submit"
        slice='bank'
        variant="warning"
        btnclassName="create-btn"
        action={"appLoadDataAsyncThunk"}
        onClick={() => dispatch(appLoadDataAsyncThunk({ networkID: network?.chainId, provider: web3Provider, address }))}
      />
    </div>
  )
}
