import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import { catchAsync, detectError, handleLoading, reduxToolKitCaseBuilder } from "../../helpers/detectError";
import { toast } from "react-toastify";

// blogs start
export const getBlogsAsyncThunk = createAsyncThunk(
  "cms/getBlogsAsyncThunk",
  catchAsync(async (params, _) => {
    const filterparams = {};
    Object.keys(params)?.map((item) => {
      if (params[item] !== "") filterparams[item] = params[item];
    });
    const response = await ApiRequests.getAllBlogPosts(filterparams);
    return response?.data;
  })
);

export const getSingleBlogAsyncThunk = createAsyncThunk(
  "cms/getSingleBlogAsyncThunk",
  catchAsync(async ({ id }, _) => {
    const response = await ApiRequests.getSigleBlogPost(id);
    return response?.data;
  }));

export const getBlogCategoriesAsyncThunk = createAsyncThunk(
  "cms/getBlogCategoriesAsyncThunk",
  catchAsync(async () => {
    const response = await ApiRequests.getBlogCategories();
    return response?.data?.data;
  })
);
// faq start
export const getFAQsAsyncThunk = createAsyncThunk(
  "cms/getFAQsAsyncThunk",
  catchAsync(async (params, _) => {
    const filterparams = {};
    Object.keys(params)?.map((item) => {
      if (params[item] !== "") filterparams[item] = params[item];
    });
    const response = await ApiRequests.getAllFAQs(filterparams);
    return response?.data;
  })
);

export const getSingleFAQAsyncThunk = createAsyncThunk(
  "cms/getSingleFAQAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getSigleFAQ(id);
    return response?.data;
  })
);

// setting start
export const getSettingAsyncThunk = createAsyncThunk(
  "cms/getSettingAsyncThunk",
  catchAsync(async () => {
    const response = await ApiRequests.getSettings();
    return response?.data?.data;
  })
);

// subscribe start
export const getSubscribedAsyncThunk = createAsyncThunk(
  "cms/getSubscribedAsyncThunk",
  catchAsync(async ({ email }, _) => {
    const response = await ApiRequests.subscribe({ email });
    if (response) {
      toast.success("Subscribed Successfully!!!", {
        autoClose: 2000,
      });
    }
    return response?.data?.data;
  })
);

//contact us
export const contactAsyncThunk = createAsyncThunk(
  "cms/contactAsyncThunk",
  catchAsync(async (data, _) => {
    const response = await ApiRequests.contact(data);
    if (response) {
      toast.success("Contact message sent Successfully!", {
        autoClose: 2000,
      });
    }
    return response?.data?.data;
  })
);
//contact us
export const feedBackAsyncThunk = createAsyncThunk(
  "cms/feedBackAsyncThunk",
  catchAsync(async (data, _) => {
    const response = await ApiRequests.userfeedback(data);
    if (response) {
      toast.success("Thanks for your feedBack!", {
        autoClose: 2000,
      });
    }
    return response?.data?.data;
  })
);
export const getfeedBacksAsyncThunk = createAsyncThunk(
  "cms/getfeedBacksAsyncThunk",
  catchAsync(async () => {
    const response = await ApiRequests.getfeedback();
    return response?.data?.data;
  })
);
// pages
export const pagesAsyncThunk = createAsyncThunk(
  "cms/pagesAsyncThunk",
  catchAsync(async (data, _) => {
    const response = await ApiRequests.pages(data);
    return response?.data?.data;
  })
);
export const singlePagesSlugAsyncThunk = createAsyncThunk(
  "cms/singlePagesSlugAsyncThunk",
  catchAsync(async ({ slug }, _) => {
    const response = await ApiRequests.pageSlug(slug);
    return response?.data;
  })
);

const initialState = {
  //news states
  allBlogs: {},
  allFAQs: {},
  allSettings: [],
  selectedBlog: [],
  selectedFAQ: [],
  blogCategories: [],
  pages: [],
  page: {},
  feedbacks: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 1000,
    prev_page_url: null,
    to: 1,
    data: []
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "cms",
  initialState,
  extraReducers: (builder) => {
    builder
      // blogs
      .addCase(getBlogsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.current_page > 1) {
          state.allBlogs = {
            ...action.payload,
            data: state?.allBlogs?.data.concat(action?.payload?.data),
          };
        } else {
          state.allBlogs = action.payload;
        }
      })
      // faqs
      .addCase(getFAQsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.current_page > 1) {
          state.allFAQs = {
            ...action.payload,
            data: state?.allFAQs?.data.concat(action?.payload?.data),
          };
        } else {
          state.allFAQs = action.payload;
        }
      })
      // settings
      .addCase(getSettingAsyncThunk.fulfilled, (state, action) => {
        state.allSettings = action.payload;
      })
      .addCase(getSingleBlogAsyncThunk.fulfilled, (state, action) => {
        state.selectedBlog = action.payload;
      })
      .addCase(getSingleFAQAsyncThunk.fulfilled, (state, action) => {
        state.selectedFAQ = action.payload;
      })
      .addCase(getBlogCategoriesAsyncThunk.fulfilled, (state, action) => {
        state.blogCategories = action.payload;
      })
      .addCase(pagesAsyncThunk.fulfilled, (state, action) => {
        state.pages = action.payload;
      })
      .addCase(getfeedBacksAsyncThunk.fulfilled, (state, action) => {
        state.feedbacks = { data: action.payload };
      })
      .addCase(singlePagesSlugAsyncThunk.fulfilled, (state, action) => {
        state.page = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...(reduxToolKitCaseBuilder([
            getBlogsAsyncThunk,
            getSingleBlogAsyncThunk,
            getBlogCategoriesAsyncThunk,
            getFAQsAsyncThunk,
            getSingleFAQAsyncThunk,
            getSubscribedAsyncThunk,
            contactAsyncThunk,
            feedBackAsyncThunk,
            pagesAsyncThunk,
            getfeedBacksAsyncThunk,
            singlePagesSlugAsyncThunk,
          ]))
        ),
        handleLoading
      );
  },
});

export default blogSlice.reducer;
export const { setLoading } = blogSlice.actions;
