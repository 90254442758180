// export const basePath = "http://127.0.0.1:8000/api/v1/";
// export const imgBasePath = "http://127.0.0.1:8000/storage/app/public/";

export const basePath = "https://api.perfectprotocol.io/api/v1/";
export const imgBasePath = "https://api.perfectprotocol.io/storage/app/public/";
export const supportedChainIds = [
  // 1337, //localhost
  56, //Binance Smart Chain Mainnet RPC
  // 4002, //fantom testnet
  // 5, //Binance Smart Chain Testnet RPC
  // 97, //Binance Smart Chain Testnet RPC
];
export const defaultChainID = 56;
export const addresses = {
  1337: {
    dbnAddress:'0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    usdtAddress:'0x5FbDB2315678afecb367f032d93F642f64180aa3',
    nftAddress:'0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    ldbnAddress:'0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    tetherBankAddress:'0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
    
  },
  56: {
    dbnAddress:'0x2A597cDe5f37d4CF2BBD9F902444f7085A977379',
    usdtAddress: '0x55d398326f99059fF775485246999027B3197955',
    nftAddress: '0xACC7325c324763e4184dc89Ff99C5f45b7Fe0FB7',
    ldbnAddress: '0xb878932ebE45Cc7948360e8EeD8Cf58dA7F12CA7',
    tetherBankAddress: '0x0e7Ad67490810B08726b419d0306474442ea867A',
  },
  4002: {
    usdtAddress: '0x52E8f883Ab2b008F74B9e695B8d157AC93aE9fdf',
    nftAddress: '0xAc6662A7c1AFEc8B506fc0bDb0431B614f23B654',
    ldbnAddress: '0xDF70Bc5f2597a6d2Bf1cB445A5Ead24b000e2B6A',
    tetherBankAddress: '0xa1b960700Ccf10c26CbAb4Ac228C43d919EC37f8',
  },
  97: {
    // usdtAddress:'0xB8804881793F36Fb4Be17BF3BED4256A25f2DB9E',
    // nftAddress:'0xffADa8400c817d4f227EcEeC2f09b18b1a2A4649',
    // ldbnAddress:'0x63D6bdc7DBd9F18CBcC2945C62e7e22a5Bb5d8D5',
    // tetherBankAddress:'0x2A597cDe5f37d4CF2BBD9F902444f7085A977379',
    usdtAddress: '0x290244DBb3Fa0A5EaC089D478CE073D68B7067a5',
    nftAddress: '0x2aA99787F8c3cC66945E7df9ee0DBF1B0E16e3bC',
    ldbnAddress: '0xBD9090938bd29E48aA9646D60ecb4322a3B936F6',
    tetherBankAddress: '0x71095fC40C84E7155d454e5fD5337397AF89dEBD',
  },
  5: {
    usdtAddress: '0x0C2F918c809F688b120C86Cb6227b2Fe024Dc148',
    nftAddress: '0xC5C7E3afBBEeEDb044e2627b963970F65031593b',
    ldbnAddress: '0xA570FbB585EFc4f993963Fd28667395bB849C483',
    tetherBankAddress: '0x4547A36947e854F12e8103a609B03ED00B5b9793',
  },
  1: {
    usdtAddress: '0x0C2F918c809F688b120C86Cb6227b2Fe024Dc148',
    nftAddress: '0xC5C7E3afBBEeEDb044e2627b963970F65031593b',
    ldbnAddress: '0xA570FbB585EFc4f993963Fd28667395bB849C483',
    tetherBankAddress: '0x4547A36947e854F12e8103a609B03ED00B5b9793',
  },
}



export const permissions = {

  // contract admin
  'admin': [
    "information",
    "Update Balance",
    "Update Balances USDT",
    "Transfer Balance",
    "Transfer LDBN",
    "Deposit USDT",
    "Deposit Dbn",
    "Deposit And Stake For",
    // "Deposit And Stake",
    "stake",
    "Claim NFT",
    "Claim LDBN",
    "widthdraw USDT",
    "widthdraw DBN",
    "Update Whitelist Addresses",
    'Add Whitelist',
    'Remove Whitelist',
    'Change Admin',
    'Transfer USDT',
    "Update Bank For LDBN",
    "Update Bank For NFT",
    "Update Withdraw Fee",
    "Update Deposit Fee",
    "Update Min Deposit",
    "Update Min Withdraw",
    "Update DBN Per USDT",
    "update One NFT for USDT",
    "Tuggle LDBN Transfer",
    "remove ownership of LDBN",
    "remove ownership of DBN",
    "Mint LDBN",
    "Burn LDBN",

  ],
  //  RESERVE_ADMIN_ROLE
  'user': [
    "information",
    "Deposit USDT",
    "Deposit DBN",
    "Transfer Balance",
    "Transfer LDBN",
    // "Deposit And Stake",
    "stake",
    "Claim NFT",
    "Claim LDBN",
    "widthdraw USDT",
  ],
  'manager': ["information",
    "Deposit USDT",
    "Deposit DBN",
    "stake",
    "Transfer Balance",
    "Transfer LDBN",
    // "Deposit And Stake",
    "Claim NFT",
    "Claim LDBN",
    "widthdraw DBN",
    'Update Balances USDT',
    'Update Balance'],
}
const navLinks = [];

export const APIurls = {
  // auth
  login: "user/login",
  register: "user/register",
  logout: "user/logout",
  //blogs
  news: "news",
  faq: "faq",
  newscategory: "newscategory",
  // getCreatorCollection: (id) => "creator/" + id + "/collections",
  // getCreatorNFTs: (id) => "creator/" + id + "/nfts",
  setting: "setting",
  subscribe: "https://api.perfectprotocol.io/api/subscribe",
  //LandingPage
  landingPage: "landingPage",
  //contact message
  contact: "contact",
  userfeedback: "userfeedback",
  page: "page",

};
