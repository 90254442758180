import { APIurls } from "../constants";
import apiService from ".";
import axios from "axios";
export const ApiRequests = {
  // auth
  web3LoginMessage: async () => await apiService.get(APIurls.web3LoginMessage),
  web3LoginVerify: async (data) =>
    await apiService.post(APIurls.web3LoginVerify, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  logout: async () => await apiService.post(APIurls.logout),
  // profile
  userprofile: async (data) =>
    await apiService.post(APIurls.userprofile + "/" + data.id, data.user),
  getprofile: async (id) =>
    await apiService.get(APIurls.userprofile + "/" + id),
  getCreatorprofile: async (id) =>
    await apiService.get(APIurls.creatorProfile + "/" + id),
  // get user collectios nft
  getUserCollections: async (params) =>
    await apiService.get(APIurls.getUserCollections, { params }),
  getUserNFTs: async (params) =>
    await apiService.get(APIurls.getUserNFTs, { params }),
  // creator
  getCreatorCollections: async (id, params) =>
    await apiService.get(APIurls.getCreatorCollection(id), { params }),
  getCreatorNFTs: async (id, params) =>
    await apiService.get(APIurls.getCreatorNFTs(id), { params }),
  //blog
  getAllBlogPosts: async (params) =>
    await apiService.get(APIurls.allBlogs, { params }),
  getSelectedBlogPost: async (id) =>
    await apiService.get(APIurls.selectedBlog + "/" + id),
  getBlogCategories: async () => await apiService.get(APIurls.blogCategories),

  //setting
  getAllSettings: async () => await apiService.get(APIurls.allSettings),

  //collection
  createCollection: async (data) =>
    await apiService.post(APIurls.createCollection, data),
  getCollections: async (tab, params) =>
    await apiService.get(APIurls.getCollections, { params: { ...params } }),
  searchQueryCollection: async (params) =>
    await apiService.get(APIurls.searchQueryCollection, { params }),
  deleteCollection: async (id) =>
    await apiService.delete(APIurls.deleteCollection + "/" + id),
  updateCollection: async (id, data) =>
    await apiService.post(APIurls.updateCollection + "/" + id, data),

  getTabCollections: async (params) =>
    await apiService.get(APIurls.getTabCollections, { params }),
  getCollection: async (id) =>
    await apiService.get(APIurls.getCollections + "/" + id),
  getNFTAgainstCollections: async (id, params) =>
    await apiService.get(APIurls.getCollections + "/" + id + "/nft", {
      params,
    }),
  checkCollectionName: async (name) =>
    await apiService.post(APIurls.checkCollectionName, { name }),

  //NFT
  createNFT: async (data) => await apiService.post(APIurls.createNFT, data),
  getAllNfts: async (params) =>
    await apiService.get(APIurls.getAllNfts, { params }),
  getNftbyId: async (id) => await apiService.get(APIurls.getNftbyId + "/" + id),
  addToFavorite: async (data) =>
    await apiService.post(APIurls.addToFavorite, data),
  deleteNFT: async (id) =>
    await apiService.delete(APIurls.deleteNFT + "/" + id),

  //categories
  getCategories: async () => await apiService.get(APIurls.categories),

  //Home
  getHomePageData: async () => await apiService.get(APIurls.Home),
  // ehter price
  getEtherPriceToUSDT: async () => await axios.get(APIurls.getEtherPriceToUSDT),
};
