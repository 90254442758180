import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import { catchAsync, detectError, handleLoading, reduxToolKitCaseBuilder } from "../../helpers/detectError";
import { toast } from "react-toastify";
import TetherBankABI from '../../abi/UsdtDeposit.sol/UsdtDeposit.json'
import LDBNABI from '../../abi/LDashbone.sol/LDashbone.json'
import USDTabi from '../../abi/USDT.sol/USDT.json'
import DBNTabi from '../../abi/DBN.sol/DBN.json'
import NFTabi from '../../abi/DashboneNFT.sol/DashboneNFT.json'



import { ethers } from "ethers";
import { addresses } from "../../constants";


// user Login With Credentials
export const appLoadDataAsyncThunk = createAsyncThunk(
    "bank/appLoadDataAsyncThunk",
    catchAsync(async ({ networkID, provider, address }, _) => {
        console.log(networkID)
        // if (!provider) {
        //     console.error("failed to connect to provider, please connect your wallet");
        //   }
        const signer = provider.getSigner();
        console.log("signer::::", signer)
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        const usdtContract = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        const nftContract = new ethers.Contract(
            addresses[networkID].nftAddress,
            NFTabi.abi,
            signer,
        );
        const dbnContract = new ethers.Contract(
            addresses[networkID].dbnAddress,
            DBNTabi.abi,
            signer,
        );
        console.log("lemlContract:", lemlContract)
        console.log("tetherBankContract:", tetherBankContract)
        console.log("usdtContract:", usdtContract)
        console.log("nftContract:", nftContract)
        const [
            myBalance,
            claimableNFT,
            claimableLDBN,
            myReward,
            deposited,
            wdrown,
            isOwner,
            isManager,
            collectedFee,
            OneNFTforUSDT,
            totalrewardGoes,
            ldbnPerUsdt,
            totalDeposit,
            withdrawFee,
            depositFee,
            totalStaked,
            checkStaked,
            minWithdraw,
            minDeposit,
            tetherInContract,
            dbnInContract,
            totalSupply,
            totalSupplyLimit,
            isTransferable,
            lemlBankAddress,
            lemlBalance,
            lemlOwner,
            nftBankAddress,
            myNFTs,
        ] = await Promise.all([
            tetherBankContract.balances(address),
            tetherBankContract.claimableNFT(address),
            tetherBankContract.claimableLDBN(address),
            tetherBankContract.rewards(address),
            tetherBankContract.checkBalance(),
            tetherBankContract.checkWithdrawnAmount(address),
            tetherBankContract.isOwner(),
            tetherBankContract.isOwner(),
            tetherBankContract.collectedFee(),
            tetherBankContract.OneNFTforUSDT(),
            tetherBankContract.totalrewardGoes(),
            tetherBankContract.ldbnPerUsdt(),
            tetherBankContract.totalDeposit(),
            tetherBankContract.withdrawFee(),
            tetherBankContract.depositFee(),
            tetherBankContract.totalStaked(),
            tetherBankContract.getStaked(),
            tetherBankContract.minWithdraw(),
            tetherBankContract.minDeposit(),

            usdtContract.balanceOf(addresses[networkID].tetherBankAddress,),
            dbnContract.balanceOf(addresses[networkID].tetherBankAddress,),
            lemlContract.totalSupply(),
            lemlContract.totalSupplyLimit(),
            lemlContract.isTransferable(),
            lemlContract.getBank(),
            lemlContract.balanceOf(address),
            lemlContract.owner(),
            nftContract.getBank(),
            nftContract.balanceOf(address),


        ])
        console.group("tetherBankContract")
        console.log("myBalance", myBalance.toString())
        console.log("myReward", myReward.toString())
        console.log("deposited", deposited.toString())
        console.log("wdrown", wdrown.toString())
        console.log("isOwner", isOwner)
        console.log("isOwner", isManager)
        console.log("tetherInContract", tetherInContract.toString() / 10 ** 18)
        console.log("dbnInContract", dbnInContract.toString() / 10 ** 18)
        console.log("totalSupply", totalSupply.toString() / 10 ** 18)
        console.log("totalSupplyLimit", totalSupplyLimit.toString() / 10 ** 18)
        console.log("totalDeposit", totalDeposit.toString() / 10 ** 18)
        console.log("totalStaked", totalStaked.toString() / 10 ** 18)
        console.log("checkStaked", checkStaked.toString() / 10 ** 18)
        console.log("minDeposit", minDeposit.toString() / 10 ** 18)
        console.log("minWithdraw", minWithdraw.toString() / 10 ** 18)
        console.log("lemlBalance", lemlBalance.toString() / 10 ** 18)
        console.log("totalrewardGoes", totalrewardGoes.toString() / 10 ** 18)
        console.log("isTransferable", isTransferable)
        console.log("lemlBankAddress", lemlBankAddress)
        console.log("nftBankAddress", nftBankAddress)
        console.log("collectedFee", collectedFee)
        console.log("ldbnPerUsdt", ldbnPerUsdt.toString())
        console.log("withdrawFee", withdrawFee.toString())
        console.log("depositFee", depositFee.toString())
        console.log("OneNFTforUSDT", OneNFTforUSDT.toString())
        console.log("myNFTs", myNFTs.toString())
        console.log("lemlOwner", lemlOwner)
        console.log("claimableLDBN", claimableLDBN)
        console.log("claimableNFT", claimableNFT)
        console.groupEnd()
        return {
            myBalance: myBalance.toString() / 10 ** 18,
            myReward: myReward.toString() / 10 ** 18,
            myNFTs: myNFTs.toString(),
            deposited: deposited.toString() / 10 ** 18,
            wdrown: wdrown.toString() / 10 ** 18,
            tetherInContract: tetherInContract.toString() / 10 ** 18,
            dbnInContract: dbnInContract.toString() / 10 ** 18,
            isOwner,
            isManager,
            lemlOwner,
            claimableNFT: claimableNFT.toString(),
            claimableLDBN: claimableLDBN.toString() / 10 ** 18,
            isTransferable,
            lemlBankAddress,
            nftBankAddress,
            collectedFee: collectedFee.toString() / 10 ** 18,
            withdrawFee: withdrawFee.toString() / 10 ** 18,
            totalDeposit: totalDeposit.toString() / 10 ** 18,
            depositFee: depositFee.toString() / 10 ** 18,
            totalStaked: totalStaked.toString() / 10 ** 18,
            checkStaked: checkStaked.toString() / 10 ** 18,
            minDeposit: minDeposit.toString() / 10 ** 18,
            minWithdraw: minWithdraw.toString() / 10 ** 18,
            lemlBalance: lemlBalance.toString() / 10 ** 18,
            totalrewardGoes: totalrewardGoes.toString() / 10 ** 18,
            ldbnPerUsdt: ldbnPerUsdt.toString(),
            OneNFTforUSDT: OneNFTforUSDT.toString() / 10 ** 18,
        }
    })
);

// pause contracts
export const pauseAsyncThunk = createAsyncThunk(
    "bank/pauseAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {
        const signer = provider.getSigner();
        const restrictedSwapContract = new ethers.Contract(
            addresses[networkID].restrictedSwapAddress,
            // RestrictedSwapAbi,
            signer,
        );
        console.log("restrictedSwapContract:", restrictedSwapContract)
        const pause = await restrictedSwapContract.pause();

        console.log("symbol:", pause)

        return {
            pause,
        }
    })
);
// stake usdt
export const stakeAsyncThunk = createAsyncThunk(
    "bank/stakeAsyncThunk",
    catchAsync(async ({ amount, uris, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.stake(ethers.utils.parseUnits(amount.toString(), 18), uris);
        console.log("symbol:", deposit)
        const result = await deposit.wait();
        console.log("result:", result)
        console.log('event data', result.events[result.events.length - 1]?.args?.ids.map(item => item.toString()))
        return {
            deposit,
        }
    })
);
// stake usdt
export const depositStakeAsyncThunk = createAsyncThunk(
    "bank/depositStakeAsyncThunk",
    catchAsync(async ({ amount, account, uris, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        // const allownce = await usdt.approve(addresses[networkID].tetherBankAddress, ethers.utils.parseUnits(amount.toString(), 18));
        // console.log("allownce:", allownce)
        // var result = await allownce.wait()
        // console.log("result:", result)

        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.depositAndStakeFor(account, ethers.utils.parseUnits(amount.toString(), 18), uris);
        console.log("symbol:", deposit)
        const result = await deposit.wait();
        console.log("result:", result)
        console.log('event data', result.events[result.events.length - 1]?.args?.ids.map(item => item.toString()))
        return {
            deposit,
        }
    })
);
// unpause
export const updateDepositFeeAsyncThunk = createAsyncThunk(
    "bank/updateDepositFeeAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.updateDepositFee(ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const updateMinDepositAsyncThunk = createAsyncThunk(
    "bank/updateMinDepositAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.updateMinDeposit(ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const updateMinWithdrawAsyncThunk = createAsyncThunk(
    "bank/updateMinWithdrawAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.updateMinWithdraw(ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const updateOneNFTForUSDTAsyncThunk = createAsyncThunk(
    "bank/updateOneNFTForUSDTAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.updateOneNFTForUSDT(ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const updateWithdrawFeeAsyncThunk = createAsyncThunk(
    "bank/updateWithdrawFeeAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.updateWithdrawFee(ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const updateMEMLPerUSDTAsyncThunk = createAsyncThunk(
    "bank/updateMEMLPerUSDTAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.updateMEMLPerUSDT(ethers.utils.parseUnits(amount.toString(), 0));

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const depositAsyncThunk = createAsyncThunk(
    "bank/depositAsyncThunk",
    catchAsync(async ({ amount, type = 1, networkID, provider }, _) => {

        const signer = provider.getSigner();


        // 
        const usdt = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        const dbn = new ethers.Contract(
            addresses[networkID].dbnAddress,
            DBNTabi.abi,
            signer,
        );
        console.log("1000000000000000000000:")
        console.log("amount:", amount)
        console.log("amount *10**18:", amount * 10 ** 18)
        console.log("amount *10**18:", ethers.utils.parseUnits(amount.toString(), 18))
        let allownce
        if (type === 1) {

            allownce = await usdt.approve(addresses[networkID].tetherBankAddress, ethers.utils.parseUnits(amount.toString(), 18));
        } else {
            allownce = await dbn.approve(addresses[networkID].tetherBankAddress, ethers.utils.parseUnits(amount.toString(), 18));
        }

        console.log("allownce:", allownce)
        const result = await allownce.wait()
        console.log("result:", result)

        // 
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.deposit(ethers.utils.parseUnits(amount.toString(), 18), type);

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// updateAdminAsyncThunk
export const updateAdminAsyncThunk = createAsyncThunk(
    "bank/updateAdminAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, _) => {

        const signer = provider.getSigner();
        // 
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.changeAdmin(account);

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// remove oawnership of leml
export const renounceOwnershipLDBNAsyncThunk = createAsyncThunk(
    "bank/renounceOwnershipLDBNAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        console.log("lemlContract:", lemlContract)
        const renounceOwnership = await lemlContract.renounceOwnership();
        console.log("renounceOwnership:", renounceOwnership)

        return {
            renounceOwnership,
        }
    })
);
// remove oawnership of leml
export const renounceOwnershipEMLBankAsyncThunk = createAsyncThunk(
    "bank/renounceOwnershipEMLBankAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const renounceOwnership = await tetherBank.renounceOwnership();
        console.log("renounceOwnership:", renounceOwnership)

        return {
            renounceOwnership,
        }
    })
);
// Claim LDBN oawnership of leml
export const ClaimLemlAsyncThunk = createAsyncThunk(
    "bank/ClaimLemlAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const claimLeml = await tetherBank.claimLeml();
        console.log("claimLeml:", claimLeml)

        return {
            claimLeml,
        }
    })
);
// Claim LDBN oawnership of leml
export const ClaimNFTAsyncThunk = createAsyncThunk(
    "bank/ClaimNFTAsyncThunk",
    catchAsync(async ({ uris, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const claimLeml = await tetherBank.claimNFT(uris);
        console.log("claimLeml:", claimLeml)

        return {
            claimLeml,
        }
    })
);
export const transferAsyncThunk = createAsyncThunk(
    "bank/transferAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount }, _) => {
        const signer = provider.getSigner();
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        console.log("address:", address)
        console.log("amount:", amount)

        console.log("lemlContract:", lemlContract)
        const transfer = await lemlContract.transfer(address, ethers.utils.parseUnits(amount.toString(), 18));

        console.log("transfer:", transfer)

        return {
            transfer,
        }
    })
);
// pause and uppause
export const tuggleTransferLDBNAsyncThunk = createAsyncThunk(
    "bank/tuggleTransferLDBNAsyncThunk",
    catchAsync(async ({ update, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        console.log("lemlContract:", lemlContract)
        const deposit = await lemlContract[update ? 'pauseTransfer' : 'resumeTransfer']();

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// updateBankAsyncThunk for NFT
export const updateBankForNFTAsyncThunk = createAsyncThunk(
    "bank/updateBankForNFTAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const nftContract = new ethers.Contract(
            addresses[networkID].nftAddress,
            NFTabi.abi,
            signer,
        );
        console.log("nftContract:", nftContract)
        const deposit = await nftContract.updateBank(account);

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// updateBankAsyncThunk for LDBN
export const updateBankAsyncThunk = createAsyncThunk(
    "bank/updateBankAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        console.log("lemlContract:", lemlContract)
        const deposit = await lemlContract.updateBank(account);

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const addWhiteListAsyncThunk = createAsyncThunk(
    "bank/addWhiteListAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, { dispatch }) => {
        const signer = provider.getSigner();
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const deposit = await tetherBank.addWhitelist(account);
        dispatch(getWhiteListedAsyncThunk({ networkID, provider }))

        console.log("symbol:", deposit)

        return {
            deposit,
        }
    })
);
// unpause
export const getWhiteListedAsyncThunk = createAsyncThunk(
    "bank/getWhiteListedAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {

        const signer = provider.getSigner();


        // 
        const usdt = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const wlisted = await tetherBank.getWhiteListedAddresses();

        console.log("wlisted:", wlisted)

        return {
            wlisted,
        }
    })
);
// unpause
export const removeWhiteListAsyncThunk = createAsyncThunk(
    "bank/removeWhiteListAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, { dispatch }) => {
        const signer = provider.getSigner();
        // 
        const usdt = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const tetherBank = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBank:", tetherBank)
        const removed = await tetherBank.removeWhitelist(account);
        dispatch(getWhiteListedAsyncThunk({ networkID, provider }))
        console.log("symbol:", removed)

        return {
            removed,
        }
    })
);
// mint
export const burnAsyncThunk = createAsyncThunk(
    "bank/burnAsyncThunk",
    catchAsync(async ({ networkID, provider, account, amount }, _) => {
        const signer = provider.getSigner();
        // 
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        console.log("lemlContract:", lemlContract)
        const burn = await lemlContract.burn(account, ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", burn)

        return {
            burn,
        }
    })
);
// mint
export const mintAsyncThunk = createAsyncThunk(
    "bank/mintAsyncThunk",
    catchAsync(async ({ networkID, provider, account, amount }, _) => {
        const signer = provider.getSigner();
        // 
        const lemlContract = new ethers.Contract(
            addresses[networkID].ldbnAddress,
            LDBNABI.abi,
            signer,
        );
        console.log("lemlContract:", lemlContract)
        const mint = await lemlContract.mint(account, ethers.utils.parseUnits(amount.toString(), 18));

        console.log("symbol:", mint)

        return {
            mint,
        }
    })
);
// mint
export const setMaxBalanceAsyncThunk = createAsyncThunk(
    "bank/setMaxBalanceAsyncThunk",
    catchAsync(async ({ networkID, provider, address, ammount }, _) => {
        const signer = provider.getSigner();
        const restrictedSwapContract = new ethers.Contract(
            addresses[networkID].restrictedSwapAddress,
            // RestrictedSwapAbi,
            signer,
        );
        console.log("restrictedSwapContract:", restrictedSwapContract)
        const unpause = await restrictedSwapContract.setMaxBalance(address, ammount);

        console.log("symbol:", unpause)

        return {
            unpause,
        }
    })
);
export const UpdateBalanceAsyncThunk = createAsyncThunk(
    "bank/UpdateBalanceAsyncThunk",
    catchAsync(async ({ networkID, provider, checked, address, amount }, _) => {
        const signer = provider.getSigner();
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBankContract:", tetherBankContract)
        const pause = await tetherBankContract.updatebalanceUSDT(address, ethers.utils.parseUnits(amount.toString(), 18), checked);

        console.log("symbol:", pause)

        return {
            pause,
        }
    })
);
// update bulk data
export const updateWhitelistAddressesAsyncThunk = createAsyncThunk(
    "bank/updateWhitelistAddressesAsyncThunk",
    catchAsync(async ({ networkID, provider, checked, accounts }, _) => {
        const signer = provider.getSigner();
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBankContract:", tetherBankContract)
        const listAdded = await tetherBankContract.updateWhitelistAddresses(accounts, checked);

        console.log("listAdded:", listAdded)

        return {
            listAdded,
        }
    })
);
// update bulk data
export const updatebalancesUSDTAsyncThunk = createAsyncThunk(
    "bank/updatebalancesUSDTAsyncThunk",
    catchAsync(async ({ networkID, provider, checked, accounts, amounts }, _) => {
        const signer = provider.getSigner();
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBankContract:", tetherBankContract)
        console.log("accounts--:", accounts)
        console.log("amounts--:", amounts)
        const pause = await tetherBankContract.updatebalancesUSDT(accounts, amounts);

        console.log("symbol:", pause)

        return {
            pause,
        }
    })
);
export const withdrawAsyncThunk = createAsyncThunk(
    "bank/withdrawAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount, type = 1 }, _) => {
        const signer = provider.getSigner();
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("tetherBankContract:", tetherBankContract)
        console.log("prams:", address, amount, type)
        const pause = await tetherBankContract.withdraw(address, ethers.utils.parseUnits(amount.toString(), 18), type);

        console.log("symbol:", pause)

        return {
            pause,
        }
    })
);
export const transferUSDTAsyncThunk = createAsyncThunk(
    "bank/transferUSDTAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount }, _) => {
        const signer = provider.getSigner();
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("address:", address)
        console.log("amount:", amount)

        console.log("tetherBankContract:", tetherBankContract)
        const transferUSDT = await tetherBankContract.transferUSDT(address, ethers.utils.parseUnits(amount.toString(), 18));

        console.log("transferUSDT:", transferUSDT)

        return {
            transferUSDT,
        }
    })
);
export const transferBalanceAsyncThunk = createAsyncThunk(
    "bank/transferBalanceAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount }, _) => {
        const signer = provider.getSigner();
        const tetherBankContract = new ethers.Contract(
            addresses[networkID].tetherBankAddress,
            TetherBankABI.abi,
            signer,
        );
        console.log("address:", address)
        console.log("amount:", amount)

        console.log("tetherBankContract:", tetherBankContract)
        const transferBalance = await tetherBankContract.transferBalance(address, ethers.utils.parseUnits(amount.toString(), 18));

        console.log("transferBalance:", transferBalance)

        return {
            transferBalance,
        }
    })
);

const initialState = {
    //news states
    analytics: {
        address: "",
        name: "",
        symbol: "",
        decimals: "",
        totalSupply: "",
        balanceOf: "",
        isPaused: "",
        myBalance: 0,
        myReward: 0,
        myNFTs: 0,
        deposited: 0,
        wdrown: 0,
        collectedFee: 0,
        depositFee: 0,
        withdrawFee: 0,
        ldbnPerUsdt: 0,
        checkStaked: 0,
        minDeposit: 0,
        minWithdraw: 0,
        totalStaked: 0,
        lemlBalance: 0,
        isOwner: false,
        isManager: false,
        lemlOwner: '0x',
        claimableLDBN: 0,
        claimableNFT: 0,
        isTransferable: false,
        tetherInContract: 0,
        OneNFTforUSDT: 0,
        totalrewardGoes: 0,
        lemlBankAddress: "",
        nftBankAddress: "",
        wlisted: []
    },

    // manager states
    errors: {},
    loadings: {},
    errorMessages: {},
    errorCodes: {},
    paramsForThunk: {},
};

const blogSlice = createSlice({
    name: "bank",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(appLoadDataAsyncThunk.fulfilled, (state, action) => {
                console.log("appLoadDataAsyncThunk:payload:::", action.payload);
                state.analytics = action.payload
            })
            .addCase(pauseAsyncThunk.fulfilled, (state, action) => {
                console.log("pauseAsyncThunk:payload:::", action.payload);

            })
            .addCase(UpdateBalanceAsyncThunk.fulfilled, (state, action) => {
                console.log("UpdateBalanceAsyncThunk:payload:::", action.payload);

            })
            .addCase(depositAsyncThunk.fulfilled, (state, action) => {
                console.log("depositAsyncThunk:payload:::", action.payload);

            })
            .addCase(getWhiteListedAsyncThunk.fulfilled, (state, action) => {
                console.log("getWhiteListedAsyncThunk:payload:::", action.payload);
                state.analytics.wlisted = action.payload.wlisted;

            })
            // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
            .addMatcher(
                // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
                isAnyOf(
                    // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
                    ...(reduxToolKitCaseBuilder([
                        appLoadDataAsyncThunk,
                        pauseAsyncThunk,
                        UpdateBalanceAsyncThunk,
                        depositAsyncThunk,
                        mintAsyncThunk,
                        setMaxBalanceAsyncThunk,
                        withdrawAsyncThunk,
                        addWhiteListAsyncThunk,
                        removeWhiteListAsyncThunk,
                        updateAdminAsyncThunk,
                        transferUSDTAsyncThunk,
                        transferBalanceAsyncThunk,
                        getWhiteListedAsyncThunk,
                        updateBankAsyncThunk,
                        tuggleTransferLDBNAsyncThunk,
                        updatebalancesUSDTAsyncThunk,
                        updateWhitelistAddressesAsyncThunk,
                        updateMEMLPerUSDTAsyncThunk,
                        updateDepositFeeAsyncThunk,
                        updateWithdrawFeeAsyncThunk,
                        updateBankForNFTAsyncThunk,
                        renounceOwnershipLDBNAsyncThunk,
                        renounceOwnershipEMLBankAsyncThunk,
                        ClaimLemlAsyncThunk,
                        ClaimNFTAsyncThunk,
                        depositStakeAsyncThunk,
                        stakeAsyncThunk,
                        updateMinWithdrawAsyncThunk,
                        updateOneNFTForUSDTAsyncThunk,
                        updateMinDepositAsyncThunk,
                        transferAsyncThunk,
                        burnAsyncThunk,
                    ]))
                ),
                handleLoading
            );
    },
});

export default blogSlice.reducer;
export const { setLoading } = blogSlice.actions;
