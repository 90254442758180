export const redirectToBuy = () => {
    window.open('http://ico.perfectprotocol.io', '_blank')
}

export const chianToName = (chain) => {
    switch (chain.toString()) {
        case '1':
            return 'Ethereum'
        case '42':
            return 'Kovan'
        case '4':
            return 'Rinkeby'
        case '3':
            return 'Ropsten'
        case '2':
            return 'Mainnet'
        case '56':
            return 'Binance Smart Chain'
        case '4002':
            return 'Fantom testnet'
        case '1337':
            return 'Hardhat'
        case '97':
            return 'Binance Testnet'
        case '5':
            return 'Goerli Testnet'
        default:
            return 'Unknown'
    }
}
export const chianToId = (chain) => {
    switch (chain) {
        case 'Ethereum':
            return '1'
        case 'Kovan':
            return '42'
        case 'Rinkeby':
            return '4'
        case 'Binance Smart Chain':
            return '56'
        case 'Binance Testnet':
            return '97'
        case 'Ropsten':
            return '3'
        case 'Mainnet':
            return '2'
        case 'Fantom testnet':
            return '4002'
        case 'Goerli testnet':
            return '5'
        case 'Hardhat':
            return '1337'
        default:
            return 'Unknown'
    }
}