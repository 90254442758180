import { setError, setErrorForReload } from "../redux/errors/handleErrorsAndPayloads";

export const detectError = (error, dispatch, rejectWithValue) => {
  console.log('error here:::::', error)
  // console.log('type error', Object.keys(error).includes('reason') || Object.keys(error).includes('code') || Object.keys(error).includes('method') )
  // 0x7920E626546f6F952912380E28F70F84B8846Ea6
  console.log(error)
  if (error['transaction']) {

    console.log('transaction:', error['transaction'])
    console.log('code:', error["code"])
    console.log('method:', error["method"])
    console.log('reason:', error["reason"])
    console.log('error:', error["error"])
    dispatch(
      setError({
        errorCode: 400,
        errorMessage:
          error?.reason || "Something went wrong",
      })
    );
  } else
    if (error.code == 4001) {
      dispatch(
        setError({
          errorCode: error.code,
          errorMessage:
            error?.message || "Something went wrong",
        })
      );
    }
  if (error.code == "ERR_NETWORK" && error.response?.status == 0) {
    dispatch(setErrorForReload({
      errorCode: 0,
      errorMessage: "Server is unavailable",
    }))
    return rejectWithValue(error);
  }
  if (error?.response) {
    if (error.response?.status == 422) {
      if (error.response?.data?.errors) {
        Object.keys(error.response?.data?.errors).map((item) =>
          dispatch(
            setError({
              errorCode: error.response.status ?? error?.response?.data?.status,
              errorMessage: `${item} : ${error.response?.data?.errors[item]}`,
            })
          )
        );
      }
    } else
      dispatch(
        setError({
          errorCode: error.response.status ?? error?.response?.data?.status,
          errorMessage:
            error?.response?.data?.message || error?.response?.data?.status,
        })
      );
  }
  if (rejectWithValue) {
    return rejectWithValue(error);
  }
};

export const spreadObjValuesNotNull = (ob) => {
  // const keysToParse = ["properties", "levels", "stats"];
  if (typeof ob === "object" && ob) {
    const tempObj = {};
    Object.keys(ob).forEach((key) => {
      // if (keysToParse.includes(key)) {
      //   tempObj[key] = ob[key] == "undefined" ? [] : JSON.parse(ob[key]);
      // } else {
      tempObj[key] = ob[key] ?? "";
      // }
    });
    return tempObj;
  } else {
    return ob;
  }
};

//convert search params to opject
export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const mapAlterString = (_array, string) => {
  if (Array.isArray(_array) && _array.length > 0) {
    return _array.map((item) => item[string]);
  } else {
    return string;
  }
};
export const subStringNumber = (stirng, numbers) => {
  if (typeof stirng == "string" && stirng.length > numbers) {
    return stirng.substring(1, numbers) + "...";
  } else {
    return stirng;
  }
};
// handle error leading and server params
function handleLoading(state, { meta, payload, type }) {
  const action = type.split("/");
  if (meta?.arg && type.endsWith("/pending")) {
    state.paramsForThunk[action[1]] = meta?.arg;
  }

  if (type.endsWith("/rejected") && payload?.response) {
    state.errorMessages[action[1]] =
      payload?.response?.data?.message ??
      payload?.response?.message ??
      "Something went wrong";
    state.errorCodes[action[1]] =
      payload?.response?.status ?? "Something went wrong";
  }
  state.errors[action[1]] = type.endsWith("/rejected");
  state.loadings[action[1]] = type.endsWith("/pending");
}
export { handleLoading };


export const catchAsync = (fn) => (_, api) => {
  return Promise.resolve(fn(_, api)).catch((error) => {
    console.error("catchAsync:error", error)
    return detectError(error, api?.dispatch, api?.rejectWithValue)
  }
  );
};
export const reduxToolKitCaseBuilder = (cases) => {
  return cases.flatMap((el) => [el.pending, el.fulfilled, el.rejected]);
};

