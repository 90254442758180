import React, { useEffect, useState } from 'react'
// import React from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import AnimatedParticles from './components/AnimatedParticles';
import { useWeb3Context } from './context'
import { useDispatch, useSelector } from 'react-redux';
import { permissions } from './constants';
import Methods from './components/Methods';
import { appLoadDataAsyncThunk } from './redux/pagesSlices/emolume_bank';

export default function Index() {

    const { web3Provider, address, network, connect } = useWeb3Context()
    console.log('error', useWeb3Context())
    const dispatch = useDispatch();
    const { analytics } = useSelector(state => state.bank)
    const { isOwner, isManager } = analytics;
    const [selectedMethod, setSelectedMethod] = useState('information')
    const Method = Methods[selectedMethod];
    useEffect(() => {
        if (address) {
            dispatch(appLoadDataAsyncThunk({ networkID: network?.chainId, provider: web3Provider, address }))
        }
        return () => {
        }
    }, [address])

    return (
        <div >
            <AnimatedParticles />
            {
                !web3Provider ?
                    <Container className='welcome-msg'>
                        <Row>
                            <Col xs={12} lg={7}>
                                <div className='content-here'>
                                    <h2>
                                        Welcome to Dashbone Bank
                                    </h2>
                                    <p>
                                        Block chain Technology has the potential to enable social and economic growth globally by offering easier.
                                    </p>
                                    <button onClick={connect} className=' create-btn btn btn-warning'>Connect wallet</button>
                                </div>
                            </Col>
                            <Col xs={12} lg={5}>
                                <div className='content-here'>
                                    <img
                                        src={require('./assets/images/animation.gif')}
                                        alt={'logo'} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <div className='container-fluid'>
                        <Row>
                            <Col xs={12} lg={3}>
                                {
                                    address && (<div className='features-section'>
                                        {
                                            permissions[isOwner ? 'admin' : isManager ? 'manager' : 'user']?.map((item, index) => (
                                                <Button
                                                    key={index}
                                                    style={{ backgroundColor: item == selectedMethod ? "#8364e2" : "#131519" }}
                                                    variant='warning' onClick={() => { setSelectedMethod(item);window.scrollTo(0, 0) }} >
                                                    <i className="fa-duotone fa-bullseye me-2"></i>
                                                    {"" + item}</Button>
                                            ))
                                        }
                                    </div>)
                                }
                            </Col>
                            <Col xs={12} lg={9}>
                                {Method && <Method networkID={network?.chainId} provider={web3Provider} address={address} />}
                            </Col>
                        </Row>
                    </div>
            }
        </div>
    );
}