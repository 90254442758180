/* eslint-disable global-require */
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Web3ContextProvider } from "./context";
import Header from './components/Header'
import Footer from './components/Footer'
import { i18n } from "@lingui/core";
import { initLocale } from "./locales";
import Models from './components/models/index';
import './assets/global.css'
import './assets/global.scss'

import { store } from "./redux/store";
import { I18nProvider } from "@lingui/react";
import { ToastContainer } from "react-toastify";

import App from "./App";
import { CustomRouter } from "./routes/CustomRouter";
import customHistory from "./routes/history";

const Root = () => {
  useEffect(() => {
    initLocale();
  }, []);

  return (
    <Provider store={store}>
      
    <Web3ContextProvider>
      <Header />
      <App />
      {/* <Footer /> */}
      <ToastContainer
        autoClose={2000}
        theme="dark"
        newestOnTop />
      <Models />
    </Web3ContextProvider>
        </Provider>
  );
};

export default Root;
