import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { updateMEMLPerUSDTAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function UpdateMEMLPerUSDT({ networkID, provider }) {

    const { analytics } = useSelector(state => state.bank)
    const { ldbnPerUsdt } = analytics;
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            dispatch(updateMEMLPerUSDTAsyncThunk({ amount: form["0"]?.value, networkID, provider }));
        }
    };

    return (
        <div className='method-component'>
            <h2>Update Mint DBN per USDT</h2>
            <p>Current value is {ldbnPerUsdt} DBN per USDT</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>

                        <Form.Group >
                            <div>
                                <Form.Control
                                    type="number"
                                    name="Amount"
                                    required
                                    // pattern="^(?=.*\d).{8,}$"
                                    placeholder="Amount..."
                                    aria-label="Amount..."
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Amount.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>

                        </Form.Group>

                    </div>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"updateMEMLPerUSDTAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
