let Methods = {};

Methods['Mint LDBN'] = require('./mint').default;
Methods['Burn LDBN'] = require('./burn').default;
Methods['freeze'] = require('./freeze').default;
Methods['grantContractAdmin'] = require('./grantContractAdmin').default;
Methods['grantTransferAdmin'] = require('./grantTransferAdmin').default;
Methods['mint'] = require('./mint').default;
Methods['pause'] = require('./pause').default;
Methods['removeContractAdmin'] = require('./removeContractAdmin').default;
Methods['removeLockUntil'] = require('./removeLockUntil').default;
Methods['removeTransferAdmin'] = require('./removeTransferAdmin').default;
Methods['setAddressPermissions'] = require('./setAddressPermissions').default;
Methods['setAllowGroupTransfer'] = require('./setAllowGroupTransfer').default;
Methods['setLockUntil'] = require('./setLockUntil').default;
Methods['setMaxBalance'] = require('./setMaxBalance').default;
Methods['setTransferGroup'] = require('./setTransferGroup').default;
Methods['upgradeTransferRules'] = require('./upgradeTransferRules').default;
Methods['information'] = require('./default').default;
Methods['Update Balance'] = require('./updateBalance').default;
Methods['Deposit USDT'] = require('./depositAsyncThunk').default;
Methods['Deposit Dbn'] = require('./depositAsyncThunk_dbn').default;
Methods['widthdraw USDT'] = require('./widthdraw').default;
Methods['widthdraw DBN'] = require('./widthdrawDBN').default;
Methods['Add Whitelist'] = require('./addWhitelist').default;
Methods['Remove Whitelist'] = require('./removeWhitelist').default;
Methods['Change Admin'] = require('./changeAdmin').default;
Methods['Transfer USDT'] = require('./transferUSDT').default;
Methods['Update Bank For LDBN'] = require('./updateBank').default;
Methods['Update Bank For NFT'] = require('./updateBankForNFT').default;
Methods['stake'] = require('./stake').default;
Methods['Tuggle LDBN Transfer'] = require('./tuggleLEMLTransfer').default;
Methods['Update Balances USDT'] = require('./updatebalancesUSDT').default;
Methods['Update Whitelist Addresses'] = require('./updateWhitelistAddresses').default;
Methods['Update DBN Per USDT'] = require('./updateMEMLPerUSDT').default;
Methods['Update Deposit Fee'] = require('./updateDepositFee').default;
Methods['Update Withdraw Fee'] = require('./updateWithdrawFee').default;
Methods['remove ownership of LDBN'] = require('./removeOwnershipOfLEML').default;
Methods['remove ownership of DBN'] = require('./removeOwnershipOfEML').default;
Methods['Claim NFT'] = require('./claimNFT').default;
Methods['Claim LDBN'] = require('./claimLeml').default;
Methods['Deposit And Stake For'] = require('./depositAndStakeFor').default;
Methods['Transfer Balance'] = require('./transferBalance').default;
Methods['Update Min Deposit'] = require('./updateMinDeposit').default;
Methods['Update Min Withdraw'] = require('./updateMinWithdraw').default;
Methods['update One NFT for USDT'] = require('./updateOneNFTForUSDT').default;
Methods['Transfer LDBN'] = require('./TransferLEML').default;



export default Methods