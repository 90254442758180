import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { UpdateBalanceAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function UpdateBalance({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(UpdateBalanceAsyncThunk({ address: form["0"]?.value, checked, amount: form["1"]?.value, networkID, provider }));
        }
    };

    return (
        <div className='method-component'>
            <h2>Update User Balance</h2>
            <p>You can update user balance from here so he/she can widthdraw quickly</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>
                        <Form.Group >
                            <div>
                                <Form.Control
                                    type="text"
                                    required
                                    pattern="^0x[a-fA-F0-9]{40}$"
                                    placeholder="Enter Wallet Address"
                                    aria-label="Enter Wallet Address"
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid wallet.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group >
                            <div>
                                <Form.Control
                                    type="number"
                                    name="amount"
                                    required
                                    // pattern="^(?=.*\d).{8,}$"
                                    placeholder="amount..."
                                    aria-label="amount..."
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid amount.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>

                        </Form.Group>
                        <Form.Group >
                            <div className="ps-2">
                                <div>
                                    <div className="form-check">
                                        <input defaultChecked value={checked} onChange={() => setChecked(!checked)} type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" for="exampleCheck1">Check to increase balance</label>
                                    </div>
                                </div>
                                <p className='mb-3 ml-3 mt-3'>{checked ? 'Balance will be added in account!' : 'Balance will be decreased in account'}
                                </p>
                            </div>
                        </Form.Group>

                    </div>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"UpdateBalanceAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
