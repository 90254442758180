import { ethers } from 'ethers';
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { updatebalancesUSDTAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function UpdatebalancesUSDT({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(true);
    const [addresses, setAddresses] = useState(['']);
    const [amounts, setAmounts] = useState([1]);



    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            var _amounts = amounts.map(item => ethers.utils.parseUnits(item?.toString(), 18))
            console.log('addresses:::::', addresses)
            console.log('_amounts::::', _amounts)
            dispatch(updatebalancesUSDTAsyncThunk({ 
                accounts: addresses,
                amounts: _amounts,
                 networkID, provider }));
        }
    };

    return (
        <div className='method-component'>
            <h2>Update Users Balances at onces</h2>
            <p>You can update users balances using one transaction from here so he/she can widthdraw quickly</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>
                        {addresses.map((sAddress, index) => (<Row>
                            <Col md={7} sm={12}>
                                <Form.Group >
                                    <div>
                                        <p>Wallet Address</p>
                                        <Form.Control
                                            id={`address-${index}`}
                                            key={`address-${index}`}
                                            name={`address-${index}`}
                                            type="text"
                                            value={addresses[index]}
                                            onChange={(e) => setAddresses(addresses.map((el, i) => i == index ? e.target.value : el))}
                                            required
                                            pattern="^0x[a-fA-F0-9]{40}$"
                                            placeholder="Enter Wallet Address"
                                            aria-label="Enter Wallet Address"
                                            aria-describedby="basic-addon2"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid wallet.
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">
                                            Looks good!
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>

                                <Form.Group >
                                    <div>
                                        <p>Amount to add</p>

                                        <Form.Control
                                            id={`amount-${index}`}
                                            type="number"
                                            key={`amount-${index}`}
                                            name={`amount-${index}`}
                                            value={amounts[index]}
                                            onChange={(e) => setAmounts(amounts.map((el, i) => i == index ? e.target.value : el))}
                                            required
                                            // pattern="^(?=.*\d).{8,}$"
                                            placeholder="amount..."
                                            aria-label="amount..."
                                            aria-describedby="basic-addon2"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid amount.
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="valid">
                                            Looks good!
                                        </Form.Control.Feedback>
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col>
                                {
                                    addresses.length > 1 &&
                                    <>
                                        <AiFillCloseCircle onClick={() => {
                                            setAddresses(addresses.filter((_, i) => i !== index))
                                            setAmounts(amounts.filter((_, i) => i !== index))
                                        }


                                        } />
                                    </>
                                }
                            </Col>
                        </Row>))}
                        <button
                            type={'button'}
                            className={`buy-btn w-100 create-btn`}
                            variant={'warning'}
                            onClick={() => {
                                setAddresses([...addresses, ""]);
                                setAmounts([...amounts, "1"]);

                            }}
                            id="button-addon2"
                        >Add More</button>
                        {/* <Form.Group >
                            <div className="ps-2">
                                <div>
                                    <div className="form-check">
                                        <input defaultChecked value={checked} onChange={() => setChecked(!checked)} type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" for="exampleCheck1">Check to increase balance</label>
                                    </div>
                                </div>
                                <p className='mb-3 ml-3 mt-3'>{checked ? 'Balance will be added in account!' : 'Balance will be decreased in account'}
                                </p>
                            </div>
                        </Form.Group> */}

                    </div>
                    <div className='contact-us mt-5'>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"updatebalancesUSDTAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
