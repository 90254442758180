import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, renounceOwnershipEMLBankAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function RemoveOwnershipOfLDBN({ networkID, provider, address }) {

    const { analytics } = useSelector(state => state.bank);
    const { lemlOwner } = analytics;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        return () => {
        }
    }, [])


    return (
        <div className='method-component'>
            <h2>Remove Ownership from DNB Bank</h2>
            <p>After you call this method no one will be remains the owner of DBN contract.</p>
            <Form noValidate>
                <div className='contact-form'>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title={'Remove Ownership'}
                                type="submit"
                                slice={'bank'}
                                action={"renounceOwnershipEMLBankAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                                onClick={(e) =>{
                                    e.preventDefault();
                                    dispatch(renounceOwnershipEMLBankAsyncThunk({ networkID, provider }))
                                }
                            }
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
