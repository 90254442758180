import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { addresses } from '../../constants';
import { updateBankForNFTAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function UpdateBankForNFT({ networkID, provider}) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(updateBankForNFTAsyncThunk({ account: form["0"]?.value,  networkID, provider }));
        }
    };

    return (
        <div className='method-component'>
             <h2>Update Bank address for mint nft</h2>
            <p>You can manage DBN by performing actions. All actions that you can perform are listed in sidebar</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <p>This is Bank Addres if you have added this in constant : {addresses[networkID].tetherBankAddress} </p>
                <div className='contact-form'>
                    <div className='select-field'>
                       
                        <Form.Group >
                        <div>
                                <Form.Control
                                    type="text"
                                    required
                                    pattern="^0x[a-fA-F0-9]{40}$"
                                    placeholder="Enter Wallet Address"
                                    aria-label="Enter Wallet Address"
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid wallet.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>

                        </Form.Group>

                    </div>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"updateBankForNFTAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
