export const web3InitialState = {
  provider: null,
  web3Provider: null,
  address: null,
  network: null,
  connect: null,
  disconnect: null,
  error: null,
  error_message: null,
}

export function web3Reducer(
  state,
  action
) {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        network: action.network,
        error: null,
        error_message: null,
      }
    case 'SET_WEB3_PROVIDER_NETWORK_NOT_SUPPORTED':
      return {
        ...state,
        error: action.error,
        error_message: action.error_message,

      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
        error: null,
        error_message: null,
      }
    case 'SET_NETWORK':
      return {
        ...state,
        network: action.network,
        error: null,
        error_message: null,
      }
    case 'RESET_WEB3_PROVIDER':
      return web3InitialState
    default:
      throw new Error()
  }
}
