import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { addWhiteListAsyncThunk, getWhiteListedAsyncThunk, removeWhiteListAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import { BiWalletAlt } from 'react-icons/bi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import CustomIconButton from '../customs/CustomIconButton';
export default function AddWhitelist({ networkID, provider }) {
    const { analytics } = useSelector(state => state.bank)
    const { wlisted } = analytics;

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(addWhiteListAsyncThunk({ account: form["0"]?.value, networkID, provider }));
        }
    };
    useEffect(() => {
        dispatch(getWhiteListedAsyncThunk({ networkID, provider }))

        return () => {

        }
    }, [])


    return (
        <div className='method-component'>
            <h2>Add Whitelist</h2>
            <p>You can manage DBN by performing actions. All actions that you can perform are listed in sidebar</p>
            <div className='mb-3'>
                {wlisted?.map((item, index) => (
                    <div className='whitlist-row'>
                        <Row >
                            <Col md={2}>
                            <i className="fa-duotone fa-wallet me-2"></i>  {index + 1} :
                            </Col>
                            <Col md={8}>
                                {item}
                            </Col>
                            <Col onClick={() => dispatch(removeWhiteListAsyncThunk({ account: item, networkID, provider }))} md={2}>
                                <CustomIconButton
                                    Icon={AiOutlineCloseCircle}
                                    type="submit"
                                    slice={'bank'}
                                    action={"removeWhiteListAsyncThunk"}
                                    variant="warning"
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>
                        <Form.Group >
                            <div>
                                <Form.Control
                                    type="text"
                                    required
                                    pattern="^0x[a-fA-F0-9]{40}$"
                                    placeholder="Enter Wallet Address for widthdraw"
                                    aria-label="Enter Wallet Address for widthdraw"
                                    aria-describedby="basic-addon2"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid wallet.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">
                                    Looks good!
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </div>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"addWhiteListAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
