import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { supportedChainIds } from '../../constants'
import { useWeb3Context } from '../../context/'
import { chianToName } from '../../hooks/globalHelpers'
import { switchNetwork } from '../../hooks/Web3CustomHooks'

const ConnectButton = ({ connect, error }) => {
  return connect ? (
    (error == "Network" ?
      // <Button variant="warning" onClick={async()=>{ 
      // await  switchNetwork(97);
      // connect();
      // }} className='create-btn'>Switch Network</Button>
      <Dropdown>
        <Dropdown.Toggle variant="warning" id="dropdown-basic">
          Switch Chain
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {supportedChainIds.map((chainId, index) => {
            return <Dropdown.Item key={index} onClick={async () => {
              await switchNetwork(chainId);
              connect();
            }}>{chianToName(chainId)}</Dropdown.Item>
          })
          }
        </Dropdown.Menu>
      </Dropdown>
      :
      <Button variant="warning" onClick={connect} className='create-btn' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <img
        width={20}
        height={20}
        src={require('../../assets/images/metamask.svg').default}
        />
        Connect
        </Button>)
  ) : (
    <button>Loading...</button>
  )
}
const DisconnectButton = ({ disconnect }) => {
  return disconnect ? (
    <Button variant="warning" onClick={disconnect} className='create-btn'>Disconnect</Button>
  ) : (
    <button>Loading...</button>
  )
}

export function Web3Button() {
  const { web3Provider, connect, disconnect, error } = useWeb3Context()

  return web3Provider ? (
    <DisconnectButton disconnect={disconnect} />
  ) : (
    <ConnectButton connect={connect} error={error} />
  )
}
