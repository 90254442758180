import React from 'react';
import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';

import { Web3Button } from './web3UI/Web3Button';
import { Web3Address } from './web3UI/Web3Address';

export const Header = () => {
    return (
        <div className='header'>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand>
                    <div className="logo">
                        <img
                            src={require('../assets/images/logo.png')}
                            alt={'logo'} />

                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className=' nav-section justify-content-end flex-grow-1 pe-3'>
                        <Web3Address />
                        <Web3Button />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header;