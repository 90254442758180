import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

export default function NetworkError() {
  const handleClose = () => {
    window.location.reload();
  }
  const show = useSelector((state) => state["error"].models['reload']);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Server is unavailable</Modal.Title>
      </Modal.Header>
      <Modal.Body>Our server is unavalable!</Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        <Button variant="primary" onClick={handleClose}>
          Reaload This Site
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
