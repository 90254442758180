
export const switchNetwork = function (reqChain) {
    return new Promise(function (resolve, reject) {
        const switchNetworkAsycn = async (reqChain) => {
            const uri_ = getNetworkUrl(reqChain);
            const customProvider = window.ethereum;
            try {
                if (!customProvider) {
                    console.log("Metamask is not installed, please install!");
                }
                await customProvider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${(reqChain).toString(16)}` }],
                });
                resolve(reqChain);
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                    console.log("This network is not available in your metamask, please add it")
                }
                const data = [{
                    chainId: `0x${(reqChain).toString(16)}`,
                    chainName: 'Binance Smart Chain',
                    nativeCurrency:
                    {
                        name: 'BNB',
                        symbol: 'BNB',
                        decimals: 18
                    },
                    rpcUrls: [uri_],
                    // blockExplorerUrls: ['https://bscscan.com/'],
                }]
                /* eslint-disable */
                try {
                    const tx = await customProvider.request({ method: 'wallet_addEthereumChain', params: data })
                    resolve(reqChain);
                } catch (error) {
                    console.log("error:connect metamask", error)
                    reject(error);
                }
            }
        };
        switchNetworkAsycn(reqChain);
    });
}



export const getNetworkUrl = (reqChain) => {
    switch (reqChain) {
        case 250:
            return getMainnetURI();
        case 4002:
            return getTestnetURI();
        case 1337:
            return getLocalhostURI();
        case 43113:
            return getFujinetURL();
        case 1:
            return getMainetTestnetURL();
        case 97:
            return getbscTestnetURL();
        case 5:
            return getgoerliTestnetURL();
        case 1:
            return getMainnetTestnetURL();
        case 56:
            return getBscMainnetURL();
        case 137:
            return getPolygonMinnetURL();
        case 80001:
            return getPolygonTestnetURL();
        default:
            return getMainnetURI();
    }
}
export const getMainnetURI = () => {
    return "https://rpc.ftm.tools";
}
export const getTestnetURI = () => {
    return "https://rpc.testnet.fantom.network/";
}
export const getFujinetURL = () => {
    return "https://api.avax-test.network/ext/bc/C/rpc";
}
export const getBscMainnetURL = () => {
    return "https://bsc-dataseed.binance.org/";
}

export function getPolygonMinnetURL() {
    return "https://polygon-rpc.com/";
}
export const getRinkebynetURL = () => {
    return "https://rinkeby.infura.io/v3/80cf9755b2474adf971635b3300fe785";
}
export const getftmLocalURL = () => {
    return "http://localhost:18545";
}
export const getbscTestnetURL = () => {
    return "https://data-seed-prebsc-1-s1.binance.org:8545/";
}
export const getgoerliTestnetURL = () => {
    return "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
}
export const getMainetTestnetURL = () => {
    return "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
}
export const getMainnetTestnetURL = () => {
    return "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
}

export const getbscMainnetURL = () => {
    return "https://bsc-dataseed.binance.org/";
}
export function getPolygonTestnetURL() {
    // return "https://rpc-mumbai.maticvigil.com/";
    return "https://rpc-mumbai.maticvigil.com/v1/b8ff58ba06931f9b801791429894ed04a2d432c5";
}

export const getLocalhostURI = () => {
    return "http://localhost:8545";
}