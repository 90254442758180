import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, tuggleTransferLDBNAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function TuggleLDBNTransfer({ networkID, provider, address }) {

    const { analytics } = useSelector(state => state.bank);
    const { isTransferable } = analytics;
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        return () => {

        }
    }, [])


    return (
        <div className='method-component'>
            <h2>Make LDBN {isTransferable ? 'Untransferable' : 'Transferable'} Bank</h2>
            <p>You can manage DBN by performing actions. All actions that you can perform are listed in sidebar</p>
            <Form noValidate>
                <div className='contact-form'>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title={isTransferable ? 'Lock Transfer' : 'Allow Tranfer'}
                                type="submit"
                                slice={'bank'}
                                action={"tuggleTransferLDBNAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                                onClick={(e) =>{
                                    e.preventDefault();
                                    dispatch(tuggleTransferLDBNAsyncThunk({ update: isTransferable, networkID, provider }))
                                }
                            }
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
